import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GlobalConfig } from '../config/config';
import { NotificationRequest } from '../objects/NotificationRequest';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private swPush: SwPush,
    private httpClient: HttpClient
  ) { }

  // public getPublicKey(): Observable<string> {
  //   return this.httpClient.get<string>(environment.notificationBaseUrl + "/publicKey");
  // }

  // Retrieve subscription info of the browser
  public getSubscription() {
    return new Observable(observer => {
      this.swPush.subscription.pipe(take(1)).subscribe(pushSubscription => {
        if (pushSubscription) {
          observer.next(pushSubscription);
        } else {
          observer.next(pushSubscription);
        }
      });
    });
  }

  //Form subscription request
  public formSubscriptionRequest(userId: string, organisation: string, subscription: any) {
    const subscriptionRequest = {
      "userId": userId,
      "organisation": organisation,
      "subscriptionInfo": subscription
    };
    return subscriptionRequest;
  }

  public async requestSubscription(userId: string, organisation: string) {
    try {
        this.getSubscription().subscribe(async response => {
          if (response) {
            // console.log(response);
          } else {
            // Subscribe to the browser
            const subscription = await this.swPush.requestSubscription({
              serverPublicKey: GlobalConfig.getConfig().notificationPublicKey
            });
            const request = this.formSubscriptionRequest(userId, organisation, subscription);
            // Call api to store the subscription info
            this.httpClient.post(GlobalConfig.getConfig().notificationBaseUrl + "/subscribe", request).subscribe(response => {
              // console.log(response);
            });
          }
        });
    } catch (err) {
      console.error('Could not subscribe due to:', err);
    }
  }

  public subscribeToNotification(userId: string, organisation: string) {
    // Validate browser notification permission to request subscription
    if(Notification.permission === 'default') {
      Notification.requestPermission().then(() => {
        this.requestSubscription(userId, organisation);
      }).catch(() => {
        // show permission denied error
      });
    }
    else if(Notification.permission === 'denied') {
      // show permission is denied, please allow it error
    } else {
      this.requestSubscription(userId, organisation);
    }
  }

  public unsubscribeToNotification(userId: string, organisation: string) {
    this.getSubscription().subscribe(subscriptionInfo => {
      const request = this.formSubscriptionRequest(userId, organisation, subscriptionInfo);
      // Call api to remove the subscription info
      this.httpClient.post(GlobalConfig.getConfig().notificationBaseUrl + "/unsubscribe", request).subscribe(response => {
        // console.log(response);
      });
    });
    // Unsubscribe from the browser
    this.swPush.unsubscribe();
  }

  public sendNotification(notificationRequest: NotificationRequest) {
    // Call api to send the notification
    this.httpClient.post(GlobalConfig.getConfig().notificationBaseUrl + "/send", notificationRequest).subscribe(response => {
      // console.log(response);
    });
  }
}
