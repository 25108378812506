import { NgModule } from '@angular/core';
import { NotificationLibComponent } from './notification-lib.component';



@NgModule({
  declarations: [NotificationLibComponent],
  imports: [
  ],
  exports: [NotificationLibComponent]
})
export class NotificationLibModule { }
