import { EnvConfig } from "./envConfig";

export class GlobalConfig {
    public static config: EnvConfig = null;

    constructor() {
    }
        
    public static setConfig(configObj: EnvConfig) {
        this.config = configObj
    }

    public static getConfig() {
        if (this.config)
            return this.config;
        else 
            return null;
    }
}